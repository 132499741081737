<template>
  <div class="lcf-index-panel">
    <transition name="van-fade" mode="out-in">
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    </transition>
    <Tabbar route class="lcf-tab-bar" fixed placeholder>
      <TabbarItem icon="home-o" to="/index/home" v-waves> 首页 </TabbarItem>
      <TabbarItem icon="contact" to="/index/user" v-waves> 我的 </TabbarItem>
    </Tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'

export default {
  components: {
    Tabbar,
    TabbarItem,
  },
}
</script>

<style lang="scss" scoped>
.lcf-index-panel {
  height: 100%;
  background: #fff; //$lcf-color-bg-gray-light;
  .lcf-tab-bar {
    ::v-deep .van-tabbar-item {
      border-radius: 8px;
    }
    &::after {
      border-top-width: 0;
    }
  }
}
</style>
